<template>
  <!-- Modal dialog: Logout -->
  <v-dialog 
    transition="dialog-top-transition"
    v-model="dialog" 
    width="500"
    @keydown.esc="close" 
    persistent>
    <v-card-title class="dialogTitleClass bg-dialog-error"><v-icon class="mr-2 icon-error">mdi-alert-circle</v-icon>{{ title }}</v-card-title>
    <v-card-text v-show="!!message" class="dialogTextClass px-6" v-html="message"></v-card-text>
    <v-card-actions>
      <v-container>
        <v-row>
          <v-col>
            <v-btn v-if="!options.noconfirm" class="btn-gray" @click.native="close">CERRAR</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-dialog>
</template>

<script>
  export default {
    name: "dialogRemove",
    data() {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
          noconfirm: false,
        },
      };
    },
    methods: {
      open(title, message, options) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      close() {
        this.resolve(false);
        this.dialog = false;
      },
    },
  };
</script>